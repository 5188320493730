.appBody {
    padding: 1px 10px 1px 10px;
    background-color: #f7f9ff;
}

.app_icon {
    border: 1pt solid rgba(50, 50, 50, 0.3);
    border-radius: 20px;
    box-shadow: rgba(200, 200, 200, 0.2) 2px 2px;
    padding: 5px;
    margin: 5px 0px 0px 0px;
}

.app_icon:hover {
    background-color: rgba(230, 230, 230, 0.2);
}

.app-controls {
    margin-bottom: 10px
}

.feature-container {
    margin-bottom: 10px;
}

.feature-content {
    padding: 10px;
    display: flex;
    align-items: center;
}

.feature-icon {
    color: white;
    background-color: green;
    border-radius: 360px;
    margin: 0px 10px 0px 0px;
}

.subTotal {
    text-align: center;
    background-color: green;
    color: white;
    padding: 5px;
    margin: 5px 1px 5px 1px;
    border-radius: 10px;
}

.screenshot_preview {
    border: 0.5pt solid rgba(50, 50, 50, 0.3);
    border-radius: 20px;
    box-shadow: rgba(200, 200, 200, 0.2) 2px 2px;
    margin: 0px;
}

.screenshot_preview:hover {
    opacity: 60%;
    cursor: pointer;
}

.info_pricing_user {
    text-align: center;
    background-color: green;
    color:white;
    border-radius: 20px;
    padding: 20px;
    font-size: large;
}

.screenshot-item {
    text-align: center;
}
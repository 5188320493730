div::-webkit-scrollbar {
    width: 0.25em;
}

div::webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

div::-webkit-scrollbar-thumb {
    background-color: #ffffff33;
    outline: 0.5px solid rgba(240,240,240,0.2);
    border-radius: 5px;
}
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(36, 53, 106);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}

.form-box::-webkit-scrollbar {
  width: 12px;
}

.form-box::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.form-box::-webkit-scrollbar-thumb {
  background-color: rgb(36, 53, 106);
  border-radius: 20px;
  border: 3px solid rgb(255, 255, 255);
}

.background-container {
  display: block;
  position: absolute;
  z-index: -1;
}

.container {
  z-index: 5;
}

.grid-container {
  height: 100vh;
}

.form-box {
  height: 60vh;
  width: 100%;
  overflow: auto;
}

.loaderBox {
  display: "flex";
  flex-direction: "column";
  width: "80vw";
  height: "60vh";
  justify-content: "center";
  align-items: "center";
}